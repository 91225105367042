<template>
  <div class="GlobalTable">
    <!-- 胶囊切换 -->
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div :class="type === '00'? 'active' : 'none'" @click="btnClick('00')">
            催货中({{ trackIng || 0 }})
          </div>
          <div :class="type === '01'? 'active' : 'none'" @click="btnClick('01')">
            已完结({{ trackEd || 0 }})
          </div>
        </div>
      </div>
      <!-- 筛选按钮 -->
      <el-checkbox style="margin-right:10px;" v-model="isQueryLockPageStaff" >筛选已锁定发货店员订单</el-checkbox>
    </div>
    <!-- Table -->
    <GlobalTable
        ref="GlobalTable"
        v-if="refresh"
        v-loading="loading"
        :columns="tableColumns"
        :data="this.seachDataList"
        :currentPage="page.pageNum"
        :total="page.total"
        @handleCurrentChange="handleCurrentChange"
        @sortChange="handleSortChange"
    > 
      <!-- 操作 -->
      <el-table-column
        fixed="right"
        label="操作"
        :width="type === '00'? 340 :150"
        align="center"
        slot="action">
        <template v-slot="{ row }" v-if="type == '00'">
          <el-button type="success" round size="mini" @click="actionClick('旧机跟踪日志',row)">旧机跟踪日志</el-button>
          <el-button type="danger" v-if="row.staffIsLockPage" round size="mini" @click="actionClick('解除锁定发货提示', row)">解除锁定</el-button>
          <el-button type="danger"  v-else round size="mini" @click="actionClick('锁定发货提示', row)">锁定发货界面</el-button>
          <el-button type="primary" round size="mini" @click="actionClick('催货完结提示', row)">催货完结</el-button>
        </template>      
        <template v-slot="{ row }" v-else>
          <el-button type="success" round size="mini" @click="actionClick('旧机跟踪日志',row)">旧机跟踪日志</el-button>
        </template>
      </el-table-column>
      <!-- 订单号 -->
      <el-table-column
        label="订单号"
        align="center"
        slot="orderNo">
        <template v-slot="{ row }" >
          <a
            v-if="row.orderNo!=='--'"
            :href="`/RecallOrder/Details?type=edit&id=${row.orderNo}`"
            target="_blank"
            style="text-decoration: underline;color: #0981ff;">
          {{ row.orderNo }}
        </a>
        <span v-else>--</span>
        </template>      
      </el-table-column>
      <!-- 成交店员 -->
      <el-table-column
        label="成交店员"
        align="center"
        slot="staffName">
        <template v-slot="{ row }" >
          <span v-if="row.staffName!=='--'">{{ row.staffName }} - {{row.staffMobile}}</span>
        <span v-else>--</span>
        </template>      
      </el-table-column>
      <!-- 订单状态 -->
      <el-table-column
        label="订单状态"
        align="center"
        slot="state">
        <template v-slot="{ row }" >
          <div style="display:flex;flex-direction: column;justify-content: center;align-items: center;">
            <span>{{ row.state }}</span>
            <a :href="`/transferManagement/spunGoods?order=${row.orderNo}`"
              target="_blank"
              style="text-decoration: underline;color: #0981ff;"
            >查看旧机跟踪</a>
          </div>
        </template>      
      </el-table-column>
      <!-- 成交天数 -->
      <el-table-column
        label="成交天数"
        align="center"
        sortable
        sortBy="afterAckDays"
        :sort-orders="['descending', 'ascending']"
        slot="afterAckDays">
        <template v-slot="{ row }" >
          <span v-if="row.afterAckDays!=='--'" style="color:red;">{{ row.afterAckDays }}天</span>
        <span v-else>--</span>
        </template>      
      </el-table-column>
      <!-- 同店员待发货旧机 -->
      <el-table-column
        label="同店员待发货旧机"
        align="center"
        slot="staffWaitSendNum"
        >
        <template v-slot="{ row }" >
          <span style="color:red;">
            {{row.staffWaitSendNum == '--' ? 0 : row.staffWaitSendNum }}台/{{ row.staffWaitSendCost == '--' ? 0 : row.staffWaitSendCost }}元
          </span>
        </template>      
      </el-table-column>
      <!-- 同门店待发货旧机 -->
      <el-table-column
        label="同门店待发货旧机"
        align="center"
        slot="storeWaitSendNum"
        >
        <template v-slot="{ row }" >
          <span style="color:red;">{{row.storeWaitSendNum == '--' ? 0 : row.storeWaitSendNum}}台/{{ row.storeWaitSendCost == '--' ? 0 : row.storeWaitSendCost }}元</span>
        </template>      
      </el-table-column>
      <!-- 催货完结标记 -->
      <el-table-column
        label="催货完结标记"
        align="center"
        slot="handlerUseDays">
        <template v-slot="{ row }" >
          <span v-if="row.handlerUseDays!=='--'">上报后{{ row.handlerUseDays }}天完结催货</span>
        <span v-else>--</span>
        </template>      
      </el-table-column>
      <!-- 催货跟进记录 -->
      <el-table-column
        label="催货跟进记录"
        align="center"
        slot="errorTrackFollowCount"
        width="120">
        <template v-slot="{ row }" >
          <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
            <div>
              <span v-if="row.errorTrackFollowCount != '0'" @click=getFollow(row) style="cursor:pointer;color:red;text-decoration:underline;">{{ row.errorTrackFollowCount }}</span>
              <span v-else style="color:#7f7f7f">暂无跟进</span>
            </div>
            <span v-if="type == '00'" style="color:red;text-decoration:underline;cursor:pointer;" @click="actionClick('添加催货跟进',row)">添加人工跟进</span>
          </div>
        </template>      
      </el-table-column>
      
      <!-- 催货跟进记录 -->
      <el-table-column
        label="最近催货反馈"
        align="center"
        slot="errorTrackFollowContent"
        width="120">
        <template v-slot="{ row }" >
          <el-tooltip
            v-if="row.errorTrackFollowContent != '--'"
            class="item"
            effect="dark"
            :content="row.errorTrackFollowContent"
            placement="top"
          >
            <div class="noteWrap">{{ row.errorTrackFollowContent }}</div>
          </el-tooltip>
          <span v-else>--</span>
        </template>      
      </el-table-column>

    </GlobalTable>
    <el-dialog
      title="旧机跟踪日志"
      :visible.sync="traceLogShow"
      width="33%">
      <div class="logStyle">
        <div style="display:flex;" v-for="(item,index) in logTraceArr" :key="index" >
          <span style="margin-right:20px;">{{item.createTime}}</span>
          <span>{{item.content}}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="traceLogShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- ～～～～～～～～～ 催货完结提示 -->
    <GlobalDialog
      @onClose="expediteGoodsShow = false; "
      @resetPopupData="expediteGoodsShow = false;"
      :show="expediteGoodsShow"
      title="上报异常催货"
      top="6%"
      :width="630"
      @submitPopupData="expediteGoodsConfig"
    >
      <div class="offline_box">
        <div class="cancel_offline_note">
          <div>机型：{{currentData.phoneName}}, 串号：{{currentData.imei}}, 背贴码：{{currentData.barCode != '--' ? currentData.barCode : currentData.orderUnique}}</div>
        </div>
        <p>是否确认完结该催货？</p >
      </div>
    </GlobalDialog>
    <!-- ～～～～～～～～～ 添加催货跟进 -->
    <GlobalDialog
      @onClose="
        errorTrackFollowContent = '';
        expediteTraceShow = false;
      "
      @resetPopupData="
        errorTrackFollowContent = '';
        expediteTraceShow = false;
      "
      :show="expediteTraceShow"
      title="添加催货跟进"
      top="6%"
      :width="630"
      @submitPopupData="expediteTraceConfig"
    >
      <div class="remarks_box">
        <h2>跟进人：{{ userName }}</h2>
        <h2>跟进内容</h2>
        <el-form>
          <el-form-item size="medium">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入跟进内容，最多500字"
              v-model="errorTrackFollowContent"
              maxlength="500"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </GlobalDialog> 
    <!-- ～～～～～～～～～ 锁定发货提示 -->
    <GlobalDialog
      @onClose="
        lockCause = '';
        command = '';
        onlockShow = false;
      "
      @resetPopupData="
        lockCause = '';
        command = '';
        onlockShow = false;
      "
      :show="onlockShow"
      title="锁定发货提示"
      top="6%"
      :width="630"
      @submitPopupData="onlockConfig"
    >
      <div class="offline_box">
        <h2>
          说明：锁定发货后，该店员在小程序页面无法进行下单、成交、收款等操作，请谨慎操作。
        </h2>
        <p>是否确认锁定{{ currentData.ackStaffName }}的发货页面？</p >
        <div class="remarks_box">
          <h2>锁定原因（必填）</h2>
          <el-form>
            <el-form-item size="medium" >
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入锁定原因，最多500字"
                v-model="lockCause"
                maxlength="500"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="动态口令" size="medium" label-width="100px">
              <el-input
                v-model="command"
                placeholder="请输入谷歌动态口令"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </GlobalDialog>
    <!-- ～～～～～～～～～ 解除锁定发货提示 -->
    <GlobalDialog
      @onClose="
        command = '';
        unlockShow = false;
      "
      @resetPopupData="
        command = '';
        unlockShow = false;
      "
      :show="unlockShow"
      title="解除锁定发货提示"
      top="6%"
      :width="630"
      @submitPopupData="unlockConfig"
    >
      <div class="offline_box">
        <h2>
          说明：请确保店员发货没问题再解除，解除后重新锁定
        </h2>
        <p>是否确认解除锁定{{ currentData.ackStaffName }}的发货页面？</p >
        <el-form>
          <el-form-item label="动态口令" size="medium" label-width="100px">
            <el-input
              v-model="command"
              placeholder="请输入谷歌动态口令"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </GlobalDialog>

    <!-- 催货跟进记录 -->
    <el-dialog
      title="催货跟进记录"
      :visible.sync="expediteVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <GlobalTable
        ref="GlobalTable"
        :columns="expediteTraceLogColumns"
        :data="dialogExpediteList"
        :maxHeight="700"
        :isPagination="false"
      >
        <template slot>
          <div style="display: flex;justify-content: space-between;height: 40px;">
              <div>催货上报时间：{{ errorTrackFollowTime }}</div>
              <div>催货完结时间：{{ errorTrackEndTime }}</div>
          </div>
          </template>
          <el-table-column label="催货反馈" slot="followContent" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="row.followContent != '--' || ''"
                class="item"
                effect="dark"
                :content="row.followContent"
                placement="top"
              >
                 <span class="noteWrap">{{ row.followContent}}</span>
              </el-tooltip>
              <span v-else>--</span>
            </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="expediteVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { getIndex, getMeta, getRecycleState, getStoreRole, guideExcelAction, pageSize, setValue,} from "@/utils/common";

export default {
  name: "Table",
  data() {
    return {
      orderType:'01',//异常上报时间（默认）：01，成交天数：02
      ackOrDesc:'01',//降序（默认）：01，升序：02
      isQueryLockPageStaff:false,
      userName:"",
      refresh:false,
      trackEd:"",
      trackIng :"",
      currentData:{},
      ackTime:[],
      tableColumns: [],
      defaultTaleColumns:[
        {
          prop: "companyName",
          label: "门店商户",
        },
        {slotName: "orderNo"}, //	订单号
        {
          prop: "storeName",
          label: "门店",
        },
        {slotName: "staffName"}, //	成交店员
        {
          prop: "phoneName",
          label: "机型",
        },
        {
          prop: "imei",
          label: "旧机串号",
        },
        {
          prop: "orderNoOneline",
          label: "矩阵/外部订单号",
          width: "130px"
        },
        {
          prop: "quotePrice",
          label: "回收金额"
        },
        {
          prop: "merchantName", 
          label: "回收商户"
        },
        {
          prop: "fxMatrixAckMerchantName",
          label: "矩阵成交商户"
        },
        {slotName: "state"}, //	订单状态
        {slotName: "afterAckDays"}, //	成交天数
        {
          prop: "shouldToName",
          label: "门店应发往",
        },
        {slotName: "staffWaitSendNum"}, //	同店员待发货旧机 staffWaitSendCost
        {slotName: "storeWaitSendNum"}, //	同门店待发货旧机 storeWaitSendCost
        {
          prop: "errorTrackFollowTime", //??
          label: "最近跟进时间",
        },  
        {
          prop: "errorTrackUserName",
          label: "异常上报人",
        },   
        {
          prop: "errorTrackTime",
          label: "异常上报时间",
        },       
        {slotName: "errorTrackFollowContent"}, //	最近催货反馈              
        {slotName: "errorTrackFollowCount"}, //	催货跟进记录              
        {slotName: "action"}
      ],
      type:'00',
      seachDataList: [],
      loading: false,
      excelLoading: false,
      allExcelLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      traceLogShow: false,//日志跟踪
      command:"", // 谷歌验证码
      expediteGoodsShow:false, //催货完结提示
      expediteTraceShow:false, //添加催货跟进
      unlockShow:false, //解除锁定发货提示
      onlockShow:false, //锁定发货提示
      lockCause:"", //锁定原因
      logTraceArr:[],
      errorTrackFollowContent:"", //跟进内容
      expediteVisible:false, //催货跟进记录 list
      expediteLoading:false, 
      expediteTraceLogColumns:[
        { label:"跟进人",prop:"followAdminName"},
        { label:"跟进时间",prop:"createTime"},
        {slotName: "followContent"}, //	催货反馈              
      ],
      dialogExpediteList: [],
      expediteParams: {},
      errorTrackFollowTime:"",//跟进时间
      errorTrackEndTime:"",//完结时间
    };
  },
  created() {
    this.userName = localStorage.getItem("userName")
    this.refresh = true
    let startTime = moment().day(moment().day() - 30).format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.ackTime = [moment(startTime).format("x"), moment(endTime).format("x")];
    this.tableColumns = [...this.defaultTaleColumns]
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    isQueryLockPageStaff(val){
      this.isQueryLockPageStaff = val
      this.handleCurrentChange()
    }
  },
  methods: {
    // 排序
    handleSortChange(params){
      console.log("sort", params);
      this.orderType = '02'
      this.ackOrDesc =  params.desc ? '01' : '02'
      this.page.pageNum = 1
      this.handleCurrentChange()
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        orderType: this.orderType,
        ackOrDesc: this.ackOrDesc,
        ackEndTime: this.SeachParams.ackEndTime,// ||this.ackTime[1],
        ackStartTime: this.SeachParams.ackStartTime,// || this.ackTime[0], 
        companyId:this.SeachParams.companyIdad,
        expressNo: this.SeachParams.expressNo,
        fxMatrixAckMerchantId:this.SeachParams.fxMatrixAckMerchantId,
        imei: this.SeachParams.imei,
        merchantId:this.SeachParams.merchantId,
        orderNo: this.SeachParams.orderNo,
        orderNoOneline: this.SeachParams.orderNoOneline,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        state: this.SeachParams.statead || "",
        isOutOrder:this.SeachParams.isOutOrder,
        deliveryId:this.SeachParams.deliveryId,
        storeId: this.SeachParams.storeId,
        trackState: this.type, // 催货状态【00：催货中（默认），01：已完结】
        isQueryLockPageStaff:this.isQueryLockPageStaff // 筛选已锁定发货店员
      };
      this.loading = true
      _api.getErrorTrackList(baseRequest).then(res => {
        if (res.code === 1) {
          console.log('~~~',res.data);
          // this.seachDataList = res.data.ipage.records;
          this.seachDataList = res.data.ipage.records.map((v, i) => {
            v = setValue(v);
            return {
              ...v,
              state: getRecycleState(v.state),
            };
          });
          this.trackEd = res.data.trackEd
          this.trackIng = res.data.trackIng
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      })
      // .catch(() => {
      //     this.trackIng = 0
      //     this.trackEd = 0
      //   });
    },
    // 胶囊按钮切换
    btnClick(type) {
      this.refresh = false
      this.isQueryLockPageStaff = false
      this.type = type
      this.$store.commit("tagsView/GOODSTRACKSTATE", type);
      this.page.pageNum = 1
      this.tableColumns = [...this.defaultTaleColumns]
      if(type == '01'){
        const columns = [...this.defaultTaleColumns]
        columns.splice(13,3,
        {slotName: "handlerUseDays"}, //催货完结标记
        {
          prop: "errorTrackEndTime",
          label: "催货完结时间",
        } )
        this.tableColumns =  columns
      }
      this.refresh = true
      this.handleCurrentChange()
    },
    actionClick(type, row){
      console.log('@@@',row);
      this.currentData = row;
      switch (type) {
        case "旧机跟踪日志":
          this.traceLogShow = true;
          this.getTraceLog({ 
            expressNo:this.currentData.expressNo != '--' ? this.currentData.expressNo : '',
            orderNo: this.currentData.orderNo != '--' ? this.currentData.orderNo : ''});
          break;
        case "催货完结提示":
          this.expediteGoodsShow = true;
          break;
        case "添加催货跟进":
          this.expediteTraceShow = true;
          break;  
        case "锁定发货提示":
          this.onlockShow = true;
          break; 
        case "解除锁定发货提示":
          this.unlockShow = true;
          break; 
      }
    },
    // ～～～～～ 催货跟进记录
    onLockClose(){
      this.expediteVisible = false;
    },
    getFollow(row){
      // 催货上报时间
    this.errorTrackFollowTime = row.errorTrackFollowTime
    this.errorTrackEndTime = row.errorTrackEndTime
      this.expediteVisible = true;
      _api.getFollow({orderNo:row.orderNo}).then((res) => {
        if (res.code === 1) {
          console.log('111111',res);
          this.dialogExpediteList = res.data
        }
      });

    },
    // ～～～～～  锁定记录
    getExpediteList() {
      this.expediteLoading  = true;
      const params = {
        command:this.command,
        lockType:'00',// 锁定 // false ==01,
        lockCause: this.lockCause,
        pageType:"01",
        staffId: this.currentData.staffId,
      };

      _api.lockStaffPage(params).then((res) => {
        if (res.code === 1) {
          console.log('111111',res);
          this.expediteLoading = false;
        }
      });
    },
    // ～～～～～ 催货完结提示
    expediteGoodsConfig() {
      const param = {
        orderNo: this.currentData.orderNo,
      };
      _api.doOver(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.expediteGoodsShow = false;
          this.orderNum = "";
          // 重拉请求
          this.handleCurrentChange(1);
        }
      });
    },
    // ～～～～～ 添加催货跟进
    expediteTraceConfig() {
      const param = {
        orderNo: this.currentData.orderNo,
        errorTrackFollowContent:this.errorTrackFollowContent
      };
      _api.doFollow(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.expediteTraceShow = false;
          this.orderNum = "";
          // 重拉请求
          this.handleCurrentChange(1);
        }
      });
    },
    // ～～～～～ 解除锁定发货提示
    unlockConfig() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态验证码");
      }
      const param = {
        command: this.command,
        lockType:'00', //解锁
        pageType:"01",
        staffId: this.currentData.staffId,
      };
      _api.lockStaffPage(param).then((r) => {
        if (r.code) {
          this.$message.success("解除锁定操作成功");
          // 关闭弹窗
          this.unlockShow = false;
          this.command = "";
          // 重拉请求
           this.handleCurrentChange(1);
        }
      });
    },
    // ～～～～～ 锁定发货提示
    onlockConfig(){
      if(!this.lockCause){
        return this.$message.error("请输入锁定原因");
      }
      if (!this.command) {
        return this.$message.error("请输入谷歌动态验证码");
      }

      this.expediteLoading  = true;
      const param = {
        command:this.command,
        lockType:'01' ,
        lockCause: this.lockCause,
        pageType:"01",
        staffId: this.currentData.staffId,
      };

      _api.lockStaffPage(param).then((r) => {
        if (r.code) {
          this.$message.success("操作成功");
          // 关闭弹窗
          this.onlockShow = false;
          // 关闭弹窗
          this.command = "";
          this.lockCause = "";
          // 重拉请求
         this.handleCurrentChange(1);
        }
      });
    },
    // 获取旧机跟踪日志
    getTraceLog(param){
      _api.getTraceLog(param).then((r) => {
        if (r.code) {
          console.log('===',r.data);
          this.logTraceArr = r.data
        }
      });
    },

  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/ .el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 280px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981FF;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.logStyle{
  border:1px solid #5a8eff;
  width:590px; 
  min-height:50px;
  max-height:400px;
  overflow-y:scroll;
  padding:10px;
  border-radius: 6px;
}

.noteWrap{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  .cancel_offline_note{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}

.remarks_box {
  width: 100%;

  > h2 {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.apply_for_tip {
  margin-bottom: 20px;
  transform: translateX(-10px);
}

/deep/ .apply_for_box .el-dialog .el-dialog__body {
  padding-left: 30px;
  padding-right: 30px;
}

.enable_box {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 6px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 6px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }

  /deep/ .el-switch__core {
    background: #B6B9D0;
  }

  /deep/ .el-switch.is-checked .el-switch__core {
    background-color: #0981FF
  }
}

.logistics-switch-dialog {
  /deep/ .header_messgae {
    margin-bottom: 34px;
    color: #FF7586;
  }

  /deep/ .tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 33px;
  }
}
</style>
